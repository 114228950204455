.btn-select-files {
    margin: 1em;
}

.item-multimedia-container {
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0.4em;
    margin-bottom: 0.5em;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #888;
}

.profile-video-pack {
    height: 10em;
}

.item-multimedia-state {
    display: flex;
    justify-content: space-between;
    padding-right: 0.5em;
    padding-left: 0.5em;
    margin-bottom: 0.5em;
}

.item-mutimedia-item {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
}

.item-mutimedia-item div {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.item-mutimedia-item video {
    width: 100%;
    margin-bottom: 1.5em;
}


.item-mutimedia-item audio {
    margin-bottom: 1.5em;
    width: 100%;

}

.item-status-multimediaP {
    color: rgb(0, 157, 255);
    font-size: 1.1em;
}

.item-status-multimediaA {
    color: rgb(27, 183, 27);
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-status-multimediaR {
    color: rgb(250, 40, 8);
    font-size: 1.1em;
}

.item-status-multimediaM {
    color: rgb(250, 150, 9);
    font-size: 1.1em;
}

.item-mutimedia-item>div {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    padding: 0 0.5em 0.5em 0.5em;
}

.item-mutimedia-item span {
    font-size: 0.8em;
    color: #888;
    border: 0px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    //bottom: 0.1em;
    display: flex;
    background-color: rgb(128, 128, 128, 0.7);
    flex: 1;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    color: white !important;
    //border-radius: 5px;
    //margin-bottom: 0.5em;
    left: 0.6em;
    right: 0.6em;
    width: 100%;
    //position: absolute;
}

.item-mutimedia-item img {
    border: 0px solid red;
    display: flex;
    width: 100%;
    border-radius: 5px;
    height: 20em;
    object-fit: cover;
}

.item-multimedia-body {
    border: 0px solid red;
    display: flex;
    flex-direction: column;
}

.item-text-file {
    color: #888;
    font-size: 0.9em;
    text-align: left;
}

.container-file-form {
    display: flex;
    flex-direction: column;
}

.container-file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #888;
    border-radius: 5px;
    text-align: center;
}

.item-image-pack {
    border: 0px solid red;
    margin: 1em;
    height: 2em;
}

.margin-left {
    margin-left: 0em;

}

.margin-rigth {
    margin-right: 0em;
}

.icon-upload {
    font-size: 2.5em;
    color: #ccc
}

.item-info-download {
    font-size: 0.8em;
    justify-content: center;
    text-align: center;
    color: #888;
}

.item-info-text {
    color: #ec92dd;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.item-pre-img {
    border: 1px solid #666;
    padding: 1em;
    border-radius: 0.3em;
    margin-top: 1em;
}

.item-pre-img  video {
    //border: 1px solid red;
    width: 100%; 

}

.item-option-file {
    //border: 1px solid red;
    display: flex;
    margin-top: 0.5em;
}
.item-option-file label{
    //border: 1px solid red;
    padding-left: 0.5em;
    flex: 1;
    display: flex;
}
/* .label-type-multimedia {
    border: 1px solid red;
} */



.item-option {
    display: flex;
}

.item-option label {
    margin-left: 0.5em;
    font-size: 0.9em;
}

.item-edit-multimedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: dashed;
    border-width: 0.2em;
    border: 0.2em dashed #777;
    padding: 1em;
    border-radius: 0.5em !important;
}

.preview-file {
    height: 10em;
    width: 100%;
    object-fit: contain;
    object-position: top;
    border-radius: 10px;
}

.opacity-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.scale-distorted {
    filter: blur(10px);
}

.btn-save-multimedia {
    width: 100%;
    margin-bottom: 0.3em;
    border-radius: 4em;
    margin-top: 0.5em;
}

.btn-save-multimedia-button {
    border-radius: 4em;
    background-color: rgba(236, 146, 221, 0.5);
    border: 1px solid #ec92dd;
    color: white;
    padding: 0.5em 1em 0.5em 1em;
    width: 100%;
}

.btn-save-multimedia-button.disabled {
    background-color: rgba(174, 108, 163, 0.5);
    border: 1px solid #a7679d;
    color: #a3a1a1;
}

.audio-player {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.audio-controls {
    margin-left: 10px;
}

.audio-playback-icon {
    cursor: pointer;
}

.item-vide-upload {
    border: 1px solid #7777;
    padding: 0.5em;
}
.item-vide-upload  video {
    width: 100%;
    margin-bottom: 0.5em;
    //border: 1px solid blue;
}
.conten-check {
    display: flex;
    flex-direction: row;
   //border: 1px solid red;
    //align-content: center;
    //align-items: center;
    //justify-content: center;
    
}
.remove-item-upload {
    z-index: 2;
    border: 1px solid #555;
    background-color: #6666;
    position: absolute;
    right: 1.5em;
    //top: 0.5em;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em;
    border-radius: 50%;
    
}
@media (max-width: 425px) {
    .item-mutimedia-item img {
        border: 0px solid red;
        display: flex;
        width: 100%;
        border-radius: 5px;
        height: 10em;
        object-fit: cover;
    }
}


@media (min-width: 700px) {
    .btn-save-multimedia {
        max-width: 10em;
        margin-left: auto;
        margin-right: auto;
    }

    .item-multimedia-container {
        width: 25em;
        margin: 0.5em;
    }

    .item-multimedia-body {}

    .container-file-form {
        display: flex;
        flex-direction: row;
    }

    .container-file-item {}

    .margin-left {
        margin-left: 0.5em;

    }

    .margin-rigth {
        margin-right: 0.5em;
    }
}