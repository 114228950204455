.container-page-principal {
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.item-page-url {
  position: relative;
  display: flex;
  flex-direction: row;
}
.item-page-url input:first-child {
  width: 25em;
  height: 2.1em;
  border-right: 0;
  border-radius: 0.5em 0 0 0.5em;
  font-size: 0.8em;
  background-color: rgb(239, 239, 239);
}

.item-page-url input:last-child {
  border-radius: 0 0.5em 0.5em 0;
}

.page-info-url {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.8em 0.5em;
  border-radius: 0.3em;
  background-color: #eee;
  color: rgb(69, 69, 69);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
