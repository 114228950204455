.container-message-info {
  padding: 0;
  padding: 2em 0.5em 0em 0.5em;
  border-radius: 0.4em;
  border: 0;
  position: relative;
}
.container-message-info-color {
  background-color: var(--secondary2B);
}

.container-message-info p {
  color: white;
  padding: 0;
  margin: 0 0 0.5em 0;
  padding: 0 0.5em 0.7em 0.5em;
  text-align: justify;
}
.message-info-btn {
  background-color: var(--secondary2B);
  border-radius: 0.4em;
  padding: 0.3em 0.8em 0.5em 0.5em;
  color: white;
  text-align: end;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.close-fieldset {
  top: -0.6em;
  margin-left: 0.7em;
}

.icon-close-fieldset {
  color: white;
  font-size: 1.2em;
}
