.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.7em;
  border: 0px solid red !important;
}

.modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0em 0.5em 0 0.5em;
  //flex: 1;
  //max-width: 500px;
  //max-height: 300px;
  //min-height: 300px;
  //height: 300px !important;
  overflow: auto;
  //border: 3px solid red;
}

.modal-footer {
  left: 0;
  right: 0;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //border: 1px solid red;
  //flex: 1;
  //max-width: 500px;
  //max-height: 500px;
}

.modal-content {
  display: flex;
  //padding: 0 1em 0 1em;
  flex-direction: column;
  width: 90%;
  z-index: 99999;
  border: 1px solid #ec92dd;
  overflow: auto;
  border-radius: 0.5em;
  background-color: rgb(34, 41, 56);
  //left: 1em;
  //right: 1em;
  //position: relative;
  //margin-left: 1em !important;
  //margin-right: 1em !important;
  
  //padding: 1em 1em 0em 1em;
  //width: auto;
  //margin: 1em;
  //flex: 1;
  //height: 300px;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.modal-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.3em;
  font-weight: bold;
  //border: 1px solid red;
  padding: 0.3em 0.5em 0 0.3em;
  //margin-top: 0.5em;
}

.icon-close-modal {
  color: rgb(42, 42, 42);
  color: var(--complement);
  cursor: pointer;
  font-size: 1.2em;
}


