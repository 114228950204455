.container-payment-principal {
  flex: 1;
  width: 100%;
}
.container-payment-principal form {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.content-item-price-payment {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 0.5em;
}
.content-item-price-payment h3 {
  text-align: center;
  list-style: square;
  border: medium none;
  border-top: 2px dashed #d0d0d0 !important;
  padding-top: 1em;
}

.item-price-payment {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-right: 1em;
  align-content: center;
  border-bottom: 1px solid rgb(225, 225, 225);
  border-left: 5px solid rgb(225, 225, 225);
}

.item-price-payment .item-description {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.item-price-payment img {
  width: 3.5em;
  height: 3.5em;
  margin-right: 0.5em;
  object-fit: contain;
  background-color: #bbb;
  padding-right: 0.5em;

}

.content-switch {
  position: relative;
  display: flex;
  flex-direction: row;
}

.title-item-price-payment {
  border-bottom: 1px solid rgb(225, 225, 225);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 0.5em;
  padding-bottom: 0.3em;
}
