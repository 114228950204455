:root {
  --primary: #a657c5;
  --secondary: #ff6b6b;
  --secondary2: #55cc8f;
  --complement: #6373c9;

  --primaryA: #c27fdc;
  --secondaryA: #ff8f8f;
  --secondary2A: #7de0ae;
  --complementA: #8996de;

  --primaryB: #781e9b;
  --secondaryB: #e92525;
  --secondary2B: #36b574;
  --complementB: #212838;
}
