form {
  width: 100%;
}

.close {
  text-align: end;
}

.close svg {
  padding: 0.3em 0.5em 0.3em 0.5em;
  color: black;
}
