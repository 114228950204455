.menu-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;

  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #18191f;
  overflow-x: hidden;
  transition: 0.5s;
  color: white;
}

.open-menu {
  width: 100%;
  color: rgb(52, 52, 52);
}

.close-menu {
  text-align: right;
  padding: 1em;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
}

.item-list-menu {
  color: white;
}
.item-list-menu a {
  color: white;
  display: block;
  padding: 1em;
  width: 75%;
  text-decoration: none;
  outline: none !important;
}

.item-list-menu a:hover {
  text-decoration: none;
  color: inherit;
}

.item-list-menu a:hover a:focus {
  text-decoration: none;
  color: inherit;
}
.item-list-menu a:hover a:active {
  text-decoration: none;
  color: inherit;
}

.lang {
  display: flex;
  padding: 1.2em;
}

.lang-item {
  float: right;
  flex: 1;
  text-align: end;
}

.lang-item span {
  margin-left: 1em;
  padding: 0.7em;
  text-decoration: underline;
  font-weight: bold;
}
