.container-imagen {
  display: flex;
  flex-direction: column;
}

.container-imagen input[type="file"] {
  display: none;
}

.container-label-image {
  height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 0.5em 0.5em 0 0;
}
.container-label-image img {
  border-radius: 0.5em 0.5em 0 0;
}
.item-imagen-select {
  position: relative;
  display: flex;
  border-radius: 0.5em;
  flex: 1;
  border: 1px solid #ccc;
  min-width: 10em;
  flex-direction: column;
  margin: 0.5em;
}
.profile-images {
  color: var(--complementA);
  font-size: 2em;
}

.imagen-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;
}

.item-imagen-select img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.show-image-perfiles {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  position: relative;
}

.block-check {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.786);
  border-radius: 0 0 0.5em 0.5em;
}
