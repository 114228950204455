.container-offer {
}

.container-offer-item {
  border: 1px solid rgb(208, 208, 208);
  border-radius: 0.5em;
  padding: 0.5em;
  margin-bottom: 1em;
}

.container-offer-title {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(42, 42, 42);
  color: var(--complement);
}

.container-offer-content {
  padding: 0.5em 0 0.5em 0;
}

.container-offer-action {
  margin-top: 0.3em;
  display: flex;
  font-size: 1.2em;
  flex-direction: row;
  justify-content: flex-end;
}

.container-offer-action svg {
  margin-left: 0.5em;
  color: var(--primary);
}

.rich-text .rdw-editor-main {
  min-height: 200px !important;
}

.offer-btn-add {
  //padding-left: 2em;
  //padding-right: 2em;
  //background-color: white;
  padding-bottom: 0.5em;
}

.offer-form-body {
}

.offer-btn-plus {
  position: fixed;
  bottom: 4em;
  right: 1.5em;
  border-radius: 50%;
  z-index: 25;
  //border: 1px solid #319e31;
  
  color: #319e31;
}

.icon-plush {
  font-size: 2em;
  z-index: 20;
  //color: rgba(69, 223, 120, 0.5);
  border: 2px solid #92ecc0;
  border-radius: 50%;
}
