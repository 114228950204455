.container-pack-global {
    border: 0px solid red;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
}

.pending-task {
    margin: 0em 1em 2em 1em;
}