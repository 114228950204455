.registration-link {
  cursor: pointer;
  text-decoration: underline;
}

.login-container {
  background-color: rgb(34, 41, 56);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  //padding: 1em;
  //width: 100%;
  //height: 100%;
  position: absolute;
  //padding-bottom: 4em;
  padding: 1em;
}

.login-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.logo {
  height: 5em;
  width: 100%;
  margin-bottom: 3em;
  object-fit: contain;
  //width: 20em;

}

.social-botton-container {
  display: flex;
  border: 1px solid rgb(143, 142, 142);
  padding: 0.8em 0 0.8em 0;
  border-radius: 0.5em;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 0.5em;
}

.icon-facebook {
  margin-right: 0.5em;
  color: white;
  font-size: 1.2em;
}

.logo-google {
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}

.social-botton-container a {
  display: block;
  margin-top: 0.5em;
  border: 1px solid green;
}

.condition-container {
  margin-top: 4em;
  bottom: 0;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 90%;
}

.condition-container p {
  padding: 0;
  margin: 0.3em 0 0 0;
}

.condition-container a {
  padding: 0;
  display: inline-block;
  color: black;
  margin-left: 0.5em;
}

.registration-container {
  margin-top: 0.5em;
  text-align: center;
}

.registration-container a {
  color: black;
}

.icon-email {
  color: rgb(255, 175, 27);
  margin-right: 0.5em;
  font-size: 1.2em;
}

.divition-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.divition-section div {
  border-top: 1px solid gray;
  height: 0px;
  width: 100%;
}

.divition-section span {
  margin-left: 1em;
  margin-right: 1em;
}

@media screen and (min-width: 480px) {
  .login-sub-container {
    width: 40%;
  }

  .login-container {
    background-color: rgb(34, 41, 56);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    //padding: 1em;
    //width: 100%;
    //height: 100%;
    position: absolute;
    //padding-bottom: 4em;
    padding: 1em;
  }
  
  .login-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20em !important;
    //width: 100% !important;
  }
  
  .logo {
    height: 5em;
    width: 100%;
    margin-bottom: 3em;
    object-fit: contain;
    //width: 20em;
  
  }
}