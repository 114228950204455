.challenger-container {
    border: 1px solid #ec92dd;
    padding: 0.5em;
    border-radius: 5px;
}
.item-challenge-sub {
    border: 0px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #888;
}


.img-category-challe {
    border: 0px solid red;
    width: 4em;
    height: 4em;
}

.challenge-descripton {
    border: 0px solid red;
    padding:0.5em 0.3em 0.3em 0.3em;
    flex: 1;
    color: white;
}

.title-sub-challenge {
    border: 0px solid red;
    margin-right: 0.5em;
    color: white;
}

.coin-sub-challenge {
    border: 0px solid red;
    margin-right: 0.3em;
    color: rgb(244, 204, 46);
}

.btn-action {
    background-color: rgba(236, 146, 221, 0.5);
    border: 1px solid #ec92dd;
    color: #ffffff;
    padding: 0.5em 2em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-action:hover {
    background-color: rgba(236, 146, 221, 1);
    transform: scale(1.05);
}

.item-challenge-sub-center {
    margin-top: 0.3em;
    border: 0px solid green;
    justify-content: center;
    align-items: center;
    display: flex;
    align-content: center;
    margin-top: 0.5em;
}

.price-challege {
    color: white;
    font-weight: bold;
}