.contentItemSocial {
    top: 10em;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-around;
    margin-bottom: 0.5em;
    right: 0.5em;
    color: #fff;
    border-radius: 4px;
    background-color: #8887;
    padding: 0.5em;
    
    //border: 1px solid red;
}
.showPortal {
    position: absolute;
    left: 0;
    top: 0;
    //border: 1px solid red;
    padding: 2em;
}
.itemSocial {
    display: flex;
    justify-content:end;
    align-items: center;
    margin-top: 0.4em;
}

.itemSocialIcon {
    width: 1.5em;
    margin-right: 0.5em;
    margin-left: 0.5em;
}
.container-history {
    position: absolute;
    bottom: 0;
    height: auto;
    top: 0;
    display: flex;
    flex: 1;
    width: 100%;
    //border: 3px solid pink;
    flex-direction: column;



    //overflow: scroll;
}

.container-image-initial {
    //border: 4px solid red;
    position: relative;
    height: 100%;
    //width: 400px;
    display: flex;
    flex: 1;
}

.qr-code-container {
    border: 5px solid white;
    display: flex;
    position: absolute;
    right: 1em;
    bottom: 10.5em;
    
}


.qr-code-container2 {
    border: 5px solid white;
    display: flex;
    position: absolute;
    right: 1em;
    bottom: 5.5em;
    
}


.screenshot-download {
    border: 1px solid red;
    position: absolute;
    z-index: 2;
    top: 1em;
    right: 1em;
    font-size: 2em;
    padding: 0.2em;
    border: 1px solid #ec92dd;
    background-color: rgba(236, 146, 221, 0.5);
    border-radius: 5px;
}
.img-initial-history {
    //border: 3px solid blue;
    height: 100%;
    object-fit: cover;
    bottom: 0;
    top: 0;
    display: flex;
    width: 100%;
    //height: auto;
}

.history-title {
    position: absolute;
    top: 4em;
    border-radius: 0 5px 5px 0;
    background-color: rgb(34, 41, 56, 0.5);
    padding: 0.4em 0.5em 0.4em 1.5em;
    //padding: 0.3em;
    color: #ec92dd !important;
    font-weight: bold;


}

.container-domain {
    //border: 4px solid pink;
    justify-content: center;
    //padding-bottom: 0.4em;
    font-size: 1.2em;
}

.container-domain p {
    text-align: center;

    color: #ec92dd !important;
    font-weight: bold;
    border-bottom: 1px solid  rgb(34, 41, 56, 0.7);
    padding-bottom: 0.2em;
    margin-bottom: 0.2em;
}

.container-item-image {
    //border-radius: 1em 1em 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgb(34, 41, 56, 0.7);
    padding-bottom: 2.5em;
    border-top: 1px solid #4d4d4d;
    padding-top: 0.5em;
    //background-color: rgba(123, 123, 123, 0.7);
    //background-color: rgba(236, 146, 221, 0.5);
    //border: 2px solid greenyellow;
    //box-shadow: -3px -3px 5px #333;




}


.item-image-history {
    height: 5em;
    display: flex;
    justify-content: space-around;
    margin-left: 1em;
    margin-right: 1em;
    //border: 1px solid blue;



}

.front-page-item-history {
    border-radius: 0.5em;
    box-shadow: 3px 3px 5px #666;
    padding: 0.2em;
    height: 6em;
    max-width: 10em;
    //border: 4px solid red;
    flex: 1;
    object-fit: cover;
}