.message-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  border-bottom: 0.2em solid #468847;
}
.message-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
  border-bottom: 0.2em solid #b94a48;
}
.message-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
  border-bottom: 0.2em solid #3a87ad;
}
.message-warning {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #faebcc;
  border-bottom: 0.2em solid #c09853;
}

.message-container {
  padding: 0.2em;
  border-radius: 0.3em;
  position: absolute;
  z-index: 4;
  flex: 1;
  right: 0em;
  left: 0em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.message-container h3,
p {
  padding: 0;
  margin: 0;
}

.message-body {
  flex: 1;
  border-right: 1px solid white;
  margin-right: 0.1em;
  margin-left: 0.1em;
}

.icon-message {
  font-size: 1.5em;
}

@media (min-width: 420px) {
  .message-container {
    padding: 0.4em;
    border-radius: 0.3em;
  }
  .message-body {
    margin-right: 0.2em;
    margin-left: 0.2em;
  }
}
