.app-footer {
  border-top: 1px solid rgba(236, 146, 221, 0.5);
  bottom: 0;
  display: flex;
  flex-direction: row;
}

.app-footer-item {
  display: flex;
  flex: 1;
  color: white;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 1.2em;
  //background-color: black;
  //border: 1px solid red;
  
}
.app-footer-item  a {
  text-align: center;
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;  
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  padding: 0.2em 0.8em 0.2em 0.8em;
  border-radius: 5px;
  //border: 1px solid greenyellow;
  //background-color: rgba(236, 146, 221, 0.5);
  //border: 2px solid #ec92dd;

  //padding: 0.2em;

  //width: 100%;
  //display: flex;
}

.app-footer-item svg {
  color: white;
  margin: 0;
  //border: 1px solid red;
  //padding: 0.5em;
}

.select-item {
  background-color: var(--primary);
}
