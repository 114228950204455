.no-found-multimedia {
   //border: 1px solid red;
    margin-top: 2em;
    padding: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.no-found-multimedia h1 {
    text-align: center;
    color: #888;
}

.no-found-multimedia-front {
    border: 4px solid green;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(67, 67, 67, 0.3)
}