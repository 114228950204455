.error {
    color: red
}


.btn-login {
    //background-color: #3498db;
    //color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .btn-login:active {
    transform: scale(0.95);
  }