.container-history-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
}

.container-item-sale {
    border: 1px solid #ec92dd !important;
    width: 100%;
    padding: 0.5em 0.5em 0 0.5em;
    border-radius: 0.3em;
    margin-bottom: 0.5em;
}

.sub-item-sale {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.sub-item-sale a {
    color: white;

}


.sale-pending {
    color: rgb(148, 182, 255);
}

.sale-date {
    color: #888;
}

.sale-price {
    color: goldenrod;
}

.sale-payment {
    color: rgb(32, 194, 32);
}

.not-sales-pack {
    margin-top: 10em;
    color: #888 !important;
}


@media (min-width: 700px) {
    .container-history-all {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
  }
