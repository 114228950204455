.text-select-img {
    font-size: 0.9em;
}

.section-input-pack {
    //border: 1px solid red;
    display: flex;
    flex-direction: row;
}

.item-a {
    display: flex;
    //border: 1px solid red;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1em;
}

.amount-ficha {
    background-color: #f4f4f4;
}

.input-data>span {
    color: #888;
    border: 0px solid red;
}

.input-data>input {
    color: #888;
    background-color: rgb(34, 41, 56);
    max-height: 1em;
    //border: 1px solid red;
}

.input-data-check {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //border: 1px solid red;
    margin-bottom: 1em;

}

.profile-container-label-image-pack {
    //border: 1px solid #888;
    padding: 1em;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //align-content: center;
    //border-radius: 7px;
    //margin-top: 0.2em;
    //position: relative;
}

.item-date-pack {
    color: #888;
    //border: 1px solid red;
    font-size: 0.9em;
}

.error-pack {
    text-align: end;
    font-size: 0.8em;
    color: red;
}

.item-image-pack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.profile-item-imagen-select-pack {
    border: 0px solid red;
}

.profile-item-imagen-select-pack input {
    display: none;
}

.profile-img-pack-1 {
    height: 10em;
    width: 100%;
    object-fit: cover;
    //object-position: top;
    border-radius: 10px;
    //border: 5px solid red !important;
}

.profile-img-pack {
    height: 10em;
    width: 100%;
    object-fit: contain;
    object-position: top;
    border-radius: 10px;
}

.packContainer {
    width: 100%;
    padding: 1em 1em 0.5em 1em;
    //padding: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
    //border: 1px solid #ec92dd;
    border: 1px solid #444;
    //border-bottom: 1px solid #ccc;
}

.item-section-pack {
    border: 0px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item-section-pack-column-img {
    display: flex;

    align-items: center;
    position: relative;
    max-height: 10em;
    position: relative;
    border: 0 !important;
    margin-bottom: 0.5em;
    //justify-content: center;
    //z-index: 10;
    //border: 1px solid blue !important;
}

.item-section-pack-column-img video {
    position: relative;
    height: 10em;
    width: 100%;
    //border: 1px solid red !important;
    //margin: ;
    //height: 100%;


}

.item-section-pack-column-img audio {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    //margin: ;
    //border: 1px solid red !important;

}


.item-section-pack-column-img img {
    border: 1px solid #888;
    padding: 0;
    position: relative;
    width: 100%;
    max-height: 11em;

    object-fit: contain;
    border-radius: 7px;
}

.item-section-pack-column {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.2em;
    padding-bottom: 0.2em;
}

.item-section-pack-column-input {
    border: 0px solid blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.item-section-pack-column-input input {
    border-radius: 5px;
    flex: 1;
    padding: 0.4em;
    font-size: 0.8em;

    background-color: #4444;
    border: 0px solid #888;
    color: #999;
    //color: greenyellow;
}

.item-pack-title {
    font-size: 0.9em;
    color: #888;
}

.item-price-pack {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 5px;
    background-color: rgb(91, 229, 91, 0.2);
    border: 1px solid rgb(91, 229, 91, 0.5);
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.3em;
}

.item-amount-pack {
    border: 1px solid #888 !important;
    padding: 0.4em;
    border-radius: 5px;
    text-align: center;
    background-color: #444;
}

.item-amount {
    font-weight: bold;
}


.select-image-pack-item {
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    //border: 1px solid #888 !important;
    border-radius: 10px;

    border: 1px dashed #888;
}



.item-price-pack div {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.item-amount-text {
    font-size: 0.9em;
    //margin-top: 0.3em;
}

.icon-pack-action-price {
    margin-right: 0.3em;
}

.item-price-pack span {
    //margin-bottom: 0.2em;
}

.icon-pack-action {
    color: white;
    font-size: 1em;
    margin-left: 0.5em;
    //border: 1px solid #ec92dd;
    background-color: rgba(236, 146, 221, 0.5);
    border: 1px solid #ec92dd;

    border-radius: 5px;
    padding: 0.3em;
}

.item-pack-description {
    border: 1px solid #888;
    padding: 0.4em;
    margin-top: 0.4em;
    border-radius: 5px;
}

.item-pack-description p {
    margin: 0 !important;
}

.item-pack-description ul {
    margin: 0 !important;
    padding-left: 1em;
}

.item-pack-description ul>li {
    margin: 0;
}


.new-pack {
    background-color: rgb(91, 229, 91, 0.2) !important;
    border: 1px solid rgb(91, 229, 91, 0.5) !important;
}

.tooltip-content {
    width: 100%;
    display: flex;
}

.tooltip-content span {
    text-align: center;
    white-space: pre-wrap;
}

.edit-upload-file {
    //border: 1px solid red;
}

.edit-image-pack {
    right: 1em;
    width: 3em !important;
    height: 3em !important;
    top: 1.5em;
    color: white;
    cursor: pointer;

    font-size: 0.5em;
    padding: 1em;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    border: 1px solid #ec92dd;
    //background-color: rgba(236, 146, 221, 0.5);
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-left-color: white;
    animation: spin 1s ease infinite;
    margin-left: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.open-child-data {
    margin-top: 0.5em;
    cursor: pointer;
    //border: 1px solid red;
    margin-bottom: 0.5em;
    text-decoration: underline;

}

.open-child-data a {
    color: white;

}

.icon-pb {
    //border: 1px solid red;
    margin-right: 0.5em;
    color: #ec92dd;
}

@media (min-width: 700px) {

    .packContainer {
        width: 20em;
        padding: 1em;
        border-radius: 5px;
        margin-bottom: 1em;
        //border: 1px solid #ec92dd;
        margin: 0.5em;
        //border-bottom: 1px solid #ccc;

    }
}