/* Estilos generales */
.content-modal-comment-pack {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: #222B;
}

.content-modal-comment-item {
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ccc;
    padding: 0.5em;
    background-color: rgba(33, 39, 53);
    border: 1px solid #444;
    border-radius: 0.5em;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

.content-modal-comment-item-sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ccc;
    width: 100%;
    padding: 0.5em;
    background-color: rgba(33, 39, 53, 0.9);
    overflow-y: auto;
    border-radius: 0.5em;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

.content-title-modal-item {
    flex: 1;
    width: auto;
    display: flex;
    justify-content: space-between;
    width: 97%;
    padding-left: 1%;
    padding-right: 1%;
}

.close-modal-pack {
    padding: 1em;
    cursor: pointer;
}

.backgronnd-pack {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* Personalización del scroll */
.content-modal-comment-item-sub::-webkit-scrollbar {
    width: 12px; /* Ancho del scroll */
}

.content-modal-comment-item-sub::-webkit-scrollbar-thumb {
    background-color: #555; /* Color del scroll */
    border-radius: 10px; /* Redondear bordes */
    border: 3px solid #333; /* Espacio alrededor del scroll */
}

.content-modal-comment-item-sub::-webkit-scrollbar-track {
    background-color: #222; /* Color de fondo del track */
    border-radius: 10px; /* Redondear bordes */
}

.card-header-he {
    //border: 1px solid greenyellow;
    display: flex;
}

.card-header-img {
    //border: 1px solid red; 
    position: relative;
}

.card-header-img img {
    border-radius: 50%;
    height: 3em;
    width: 3em;

}
.card-header-name {
    //border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5em;
}
.preview-item-img {
    //border: 1px solid red;
    max-height: 15em;
    position: relative;
    width: 100%;
}
.preview-item-img img {
    object-fit: contain;
    position: relative;
    height: 100%;
    width: 100%;
}

.preview-item-img video {
    object-fit: contain;
    position: relative;
    height: 100%;
    width: 100%;
}


.contain-comment-client {
    //border: 1px solid greenyellow;
    position: relative;
    
    width: 100%;
}

/* Medias queries */
@media (min-width: 700px) {
    .content-modal-comment-pack {
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        background-color: #222B;
    }
    
    .content-modal-comment-item {
        width: 42em;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ccc;
        padding: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
        background-color: rgba(33, 39, 53);
        border: 1px solid #444;
        border-radius: 0.5em;
        height: 100%;
        overflow-x: hidden;
        position: relative;
    }
    
    .content-modal-comment-item-sub {
        width: 42em;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ccc;
        padding: 1em;
        margin-bottom: 2em;
        background-color: rgba(33, 39, 53, 0.9);
        overflow-y: auto;
        border-radius: 0.5em;
        height: 100%;
        overflow-x: hidden;
        position: relative;
    }
    
    .content-title-modal-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .close-modal-pack {
    }
    
    .backgronnd-pack {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
