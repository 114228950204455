.container-profile-principal {
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

}

.container-profile-principal textarea {
  border: 1px solid #ccc;
}

.container-profile-principal input {
  border: 1px solid #ccc;
}

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}


.profile-item-imagen-select-item {

  border-radius: 1em;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  margin-bottom: 0.5em;
}

.profile-item-imagen-select-item>span {
  color: #888;
}

.profile-item-imagen-select-item input[type="file"] {
  display: none;
}

.profile-container-label-image-item2 {
  border: 0px solid #888;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 10em;
  width: 100%;
}

.profile-container-label-image-item {
  border: 1px solid #888;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 10em;
  border-radius: 10px;
  width: 100%;
  display: flex;
}

.profile-images2 {
  color: #888;
  font-size: 2em;
}

.profile-item-image {
  height: 10em;
  width: 10em;
  border: 1px solid #888 !important;

}

.text-upload-file {
  color: #888;
}

.profile-item-image div {
  padding: 0.5em;
  text-align: center;
  color: #888;
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  object-fit: cover;
  object-position: top;
  position: relative;
  flex: 1;
  margin: auto;
  border-radius: 50%;
  height: 10em;
  width: 10em;
  border: 1px solid #888;
  //height: 100%;
  //border-radius: 10;
  //border: 1px solid red;


}

.profile-img-front-page {
  border: 0px solid blue;
  display: flex;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  position: relative;
  margin: auto;
  flex: 1;
  height: 100%;
  width: 100%;
}


.rdw-editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 0.3em;

}

.editor-class {
  min-height: 10em;
  max-height: 10em;
}

.input-checkbox {
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 0.7em;
  color: #888;
}

.input-checkbox input {
  margin-right: 0.5em;
}


@media (min-width: 700px) {
  .container-profile-principal {
    width: 40em;
    margin: auto;

    border: 1px solid #ec92dd;
    padding: 1em;
    border-radius: 5px;
  }
}