body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rdw-editor-toolbar {
  background-color: rgb(34, 41, 56) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #888 !important;
}
.rdw-option-wrapper {
  }

.rdw-option-wrapper img {
  //color: red;

}
.app-container {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #212838;;
}

/***** Input global */
.input-data {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5em;
}

.input-data span {
  margin-bottom: 0.2em;
  display: block;
}
.error {
  color: 'red';
  font-size: 0.8em;
  margin-top: 0.5em;
}
.error-message {
  margin-top: 0.3em;
  
  font-size: 0.9em;
  color: red !important;
}
.input-data input {
  display: flex;
  border: 1px solid rgb(195, 195, 195);
  border-radius: 0.5em;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 2em;
  flex: 1;
  padding: 0.6em 0% 0.6em 2%;
}
.input-data input {
  background-color: transparent !important;
}
.input-data input:focus {
  background-color: transparent;
  border: 1px solid rgb(195, 195, 195) !important;
}

.input-data textarea {
  display: flex;
  border: 1px solid rgb(143, 142, 142);
  border-radius: 0.5em;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 5em;
  padding: 0.6em 0% 0.6em 2%;
}

.input-data textarea:focus {
  background: white;
  border: 1px solid black;
  outline: none;
  -webkit-appearance: none;
}

.input-data input:focus {
  background: white;
  border: 1px solid black;
  outline: none;
  -webkit-appearance: none;
}

.input-data button {
  margin-top: 0.5em;
  display: flex;
  background-color: rgba(236, 146, 221, 0.5);
  color: white;
  font-weight: bold;
  font-size: 1em;
  border-radius: 0.5em;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 2.3em;
  padding: 0.6em 0 0.5em 0%;
  border: 1px solid #ec92dd;
  //background-color: var(--primary);
}

.input-data > select {
  padding: 0.6em;
  border-radius: 0.5em;
  display: flex;
  border: 1px solid rgb(195, 195, 195);
  font-weight: bold;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  margin: 0em 0 0 0;
}

.hidden {
  display: none !important;
}
