.app-header {
  height: 3em;
  font-size: 1.2em;
  background-color: var(--complementB);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
  padding: 0 0.5em 0 0.5em;
}

.app-header svg {
  color: white;
}
.app-header svg:hover {
  color: var(--complementA);
  font-weight: bold;
}
