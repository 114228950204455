.card {
  border-radius: 8px;
  width: 100%;
  margin: 20px auto;
  padding-bottom: 2em;
  //border: 1px solid red;
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  //max-width: 600px;
  //min-width: 600px;
  //padding: 20px;
  //background-color: white;
  
}

.button {
  border-radius: 4px;
  background-color: #1877f2;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0.5em 1em 0.5em 1em;
  //padding: 8px 12px;
}

.input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 0.5em;
  background-color: #4444;
  border: 0px solid #ddd;
  color: white;
}

.input:focus {
  border: 0;
  outline: none;
  border: none;
}

.avatar {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-container {
  display: flex;
  margin-top: 1em;
  //border: 1px solid red;
}

.comment-content {
  margin-left: 12px;
  flex: 1;
  //padding-top: 0.6em;
}

.comment-bubble {
  border-radius: 10px;
  padding: 8px 12px;
  margin-bottom: 4px;
  background-color: #8884;
}

.author-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2px;
}

.comment-text {
  font-size: 14px;
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: #65676b;
  display: flex;
  justify-content: space-between;
  //border: 1px solid red;
}

.count-like {
  color: #ccc;
  //border: 1px solid red;
}

.action-button {
  margin-right: 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #65676b;
  font-size: 12px;
  text-decoration: underline;
}

.reply-container {
  margin-left: 44px;
  margin-top: 8px;
  //border: 2px solid blue;
  display: flex;
}

.new-comment-form {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  bottom: 0em;
  background-color:  rgba(33, 39, 53);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: auto;

}
.new-comment-form  Button {  
  margin-right: 0.5em;
}

.avatar-aux {
  margin-left: 0.5em;
}

.collapse-button {
  margin-right: 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #65676b;
  font-size: 12px;
}

.notComment {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #888;
  font-size: 1.3em;
}

.avatar-aux {
  border: 1px solid #666;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #4444;
}

@media (min-width: 700px) {

  .card {
    border-radius: 8px;
    width: 100%;
    margin: 20px auto;
    
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    //border: 1px solid red;
    //max-width: 600px;
    //min-width: 600px;
    //padding: 20px;
    //background-color: white;
  }
  
  .button {
    border-radius: 4px;
    background-color: #1877f2;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 0.5em 1em 0.5em 1em;
    //padding: 8px 12px;
  }
  
  .input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 14px;
    margin-right: 0.5em;
    background-color: #4444;
    border: 0px solid #ddd;
    color: white;
  }
  
  .input:focus {
    border: 0;
    outline: none;
    border: none;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .comment-container {
    display: flex;
    margin-top: 1em;
    //border: 1px solid red;
  }
  
  .comment-content {
    margin-left: 12px;
    flex: 1;
    //padding-top: 0.6em;
  }
  
  .comment-bubble {
    border-radius: 10px;
    padding: 8px 12px;
    margin-bottom: 4px;
    background-color: #8884;
  }
  
  .author-name {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 2px;
  }
  
  .comment-text {
    font-size: 14px;
  }
  
  .comment-actions {
    display: flex;
    font-size: 12px;
    color: #65676b;
    display: flex;
    justify-content: space-between;
    //border: 1px solid red;
  }
  
  .count-like {
    color: #ccc;
    //border: 1px solid red;
  }
  
  .action-button {
    margin-right: 16px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #65676b;
    font-size: 12px;
    text-decoration: underline;
  }
  
  .reply-container {
    margin-left: 44px;
    margin-top: 8px;
    //border: 2px solid blue;
    display: flex;
  }
  
  .new-comment-form {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    bottom: 1.6em;
    width: 42em;
    margin: auto;
    //border: 1px solid red;
  }
  
  .collapse-button {
    margin-right: 16px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #65676b;
    font-size: 12px;
  }
  
  .notComment {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #888;
    font-size: 1.3em;
  }
  

}